
import '../Pages/Dashboard.css';

import React, { Fragment, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

import {Routes, Route, useNavigate, } from 'react-router-dom';
import moment from "moment";

import {motion} from 'framer-motion'

import { FaStoreAltSlash } from 'react-icons/fa';


function Dashboard() {

  const [isShown, setIsShown] = useState(false);    
  const [users, setUsers] = useState("");
  const [inductedLast7Days, setInductedLast7Days] = useState("");

  const handleClick = event => {
        setIsShown(current => !current);
    };


    const navigate = useNavigate();

    const navigateInduction = () => {
      navigate('/induction');
    };

    const navigateDatabase = () => {
      navigate('/database');
    };

    const navigateOrchard = () => {
      navigate('/orchard');
    };

    const navigatePackhouse = () => {
      navigate('/packhouse');
    };

    const navigateFields = () => {
      navigate('/fields');
    };

    const navigateCornHarvest = () => {
      navigate('/corn-harvest');
    };

    const navigateCornPacking = () => {
      navigate('/corn-packing');
    };

    const navigateFruitshop = () => {
      navigate('/fruitshop-language');
    };

    const getStaffInducted = async () =>{
     
      var jsonData2 = {};
        try {
          const response2 = await fetch(
            `${localStorage.getItem("url")}/api/staff_inducted`
          );
          jsonData2 = await response2.json();
          
        } catch (err) {
         
          console.log(err.message);
        }
    
        setUsers(jsonData2);

       
    
      
    };

    const getStaffInductedLast7Days = async () =>{
     
      var jsonData2 = {};
        try {
          const response2 = await fetch(
            `${localStorage.getItem("url")}/api/staff_inducted_last_7_days`
          );
          jsonData2 = await response2.json();
          
        } catch (err) {
         
          console.log(err.message);
        }
    
        setInductedLast7Days(jsonData2);

       
    
      
    };

    
  useEffect(()  => {

   getStaffInducted()
   getStaffInductedLast7Days();
    
        }, []);

  return (
    <motion.div 
    initial={{opacity: 0, x: 50}} 
    animate={{opacity: 1, x: 0}} 
    exit={{opacity: 0, x: -50}}
    transition={{ ease: "easeInOut", duration: 0.5 }}
    >
    <div className="dashboard_container">
        <div className="dashboard_content">
          <div className="dashboard_header">
            <h1>Welcome back,</h1>
          </div>
          <div className="dashboard_stats">
              <button className="button_stat" onClick={navigateDatabase}>
                <span className="text_stat" >{users.length}</span>
                <span className="text_stat2"> Staff Inducted</span>
              </button>
              <button className="button_stat" onClick={navigateDatabase}>
                <span className="text_stat">{inductedLast7Days.length}</span>
                <span className="text_stat2"> In Last 7 days</span>
              </button>
          </div>
        </div>
        <div className="induction_content">
            <h2>Induction</h2>
            <div className="induction_button">
              <button className="button_induction" onClick={navigateOrchard}>
                <span className="text_induction">Orchard</span>
                <span className="text_induction2"><br/>Induction</span>
              </button>
              <button className="button_induction" onClick={navigatePackhouse}>
                <span className="text_induction">Packhouse</span>
                <span className="text_induction2"><br/>Induction</span>
              </button>
              <button className="button_induction" onClick={navigateFields}>
                <span className="text_induction">Paddock</span>
                <span className="text_induction2"><br/>Induction</span>
              </button>
              <button className="button_induction" onClick={navigateCornHarvest}>
                <span className="text_induction">Corn Harvest</span>
                <span className="text_induction2"><br/>Induction</span>
              </button>
              <button className="button_induction" onClick={navigateCornPacking}>
                <span className="text_induction">Corn Packing</span>
                <span className="text_induction2"><br/>Induction</span>
              </button>
            </div>
        </div>
        <div className="induction_database-content">
          <h2>Database</h2>
          <div className="database_button">
            <button className="button_database" onClick={navigateDatabase}>
              <span className="text_database">Induction</span>
              <span className="text_database2"><br/>Database</span>
            </button>
          </div>
        </div>
    </div>
    </motion.div>
  )
}

export default Dashboard
